@keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-up {
    animation-name: fade-in-up;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes fade-in-left {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fade-in-left {
    animation: fade-in-left 1s ease-out;
  }
  